.wrapper {
  width: 100%;
  overflow: hidden;
  padding: 55px 170px 30px;
  background-color: #181821;
  color: #a9b3bb;
}
.infor {
  width: 100%;
  display: flex;
  gap: 15px;
  flex: 1;
}
.list-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.header-title {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 10px;
}
.logo {
  border-radius: 8px;
  height: 38px;
  margin-right: 10px;
  width: 38px;
}
.logo-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 6px;
  text-decoration: none;
  cursor: pointer;
}
.list-footer {
  & > div {
    display: flex;
    justify-content: flex-start;
    & > span {
      padding: 8px 0;
      display: block;
      text-align: start;
    }
  }
}
.copyright-container {
  padding: 40px 0;
  width: 100%;
  & > span {
    color: var(--primary-color);
    font-style: italic;
    font-size: 1.2rem;
  }
}
.copyright {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.channel {
  display: flex;
  gap: 2px;

  & > a:not(:first-child) {
    & > img {
      border-radius: 8px;
      height: 38px;
      margin-right: 10px;
      width: 38px;
    }
    cursor: pointer;
  }
  & > :first-child {
    & > img {
      border-radius: 8px;
      height: 38px;
      margin-right: 10px;
      width: 12 0px;
    }
    cursor: pointer;
  }
}
//responsive

@media (max-width: 1024px) {
  .wrapper {
    padding: 54px 30px 30px;
  }
}
@media (max-width: 768px) {
  .list-footer {
    width: 40%;
    margin-left: 3px;
  }
  .infor {
    flex-wrap: wrap;
  }
  .wrapper {
    padding: 55px 30px 30px;
  }
}
@media (max-width: 576px) {
  .list-footer {
    width: 90%;
    margin-left: 10px;
  }
  .infor {
    flex-wrap: wrap;
  }
  .wrapper {
    padding: 55px 5px 30px;
  }
  .copyright-container {
    padding: 5px 0;
    margin-left: 10px;
    width: 100%;
  }
  .copyright {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}
