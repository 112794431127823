.wrapper {
  overflow: hidden;
  padding: 0 20px 174px 10px;
}
.container {
  display: flex;
  & > div:first-child {
    flex: 2;
  }
  & > div:last-child {
    margin-top: 5px;
    flex: 0.9;
  }
  padding-bottom: 250px;
}
.intro {
  & > h1 {
    font-size: 3.2rem;
    font-weight: 700;
    margin-top: 8px;
    min-height: 33px;
  }
  & > span {
    color: rgba(0, 0, 0, 0.8);
    font-size: 1.4rem;
    line-height: 1.6;
  }
  & > div {
    & > h2 {
      font-size: 2rem;
      font-weight: 700;
      margin: 36px 0 4px;
    }
  }
}
.benefits {
  display: flex;
  margin-top: 10px;
  flex: 1;
}
.link-intro {
  padding: 20px 20px;
  h3 {
    color: #757575;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.8;
    text-transform: uppercase;
  }
  & > div {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
}
.benefits-list {
  display: flex;
  flex: 1;
  gap: 8px;
  flex-direction: column;
  align-items: flex-start;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    color: var(--primary-color);
    & > span {
      font-size: 1.4rem;
      line-height: 1.6;
      color: #333;
    }
  }
}
.document {
  & > div {
    & > h2 {
      font-size: 2rem;
      font-weight: 700;
      margin: 36px 0 4px;
    }
  }
}
.basic-course {
  margin-top: 12px;
  background: #f5f5f5;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  padding: 0;
  top: 66px;
  padding: 12px 20px 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  & > span {
    font-size: 1.3rem;
    text-align: center;
  }
  & > div {
    display: flex;
    align-items: center;
    & > span {
      color: var(--text-color);
      font-size: 1.6rem;
      font-weight: 600;
      overflow: hidden;
      position: relative;
      display: block;
      margin-left: 10px;
    }
  }
  &:hover {
    cursor: pointer;
  }
}
.program {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  & > span {
    color: rgba(0, 0, 0, 0.8);
    font-size: 1.4rem;
    line-height: 1.6;
  }
  & > a {
    color: var(--primary-color);
    font-size: 1.4rem;
    line-height: 1.6;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
.advertisement-desktop {
  display: block;
}
.advertisement-mobile {
  display: none;
}
.container-advertisement {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px 20px;
  & > h5 {
    color: var(--primary-color);
    font-size: 32px;
    font-weight: 400;
    margin: 0 auto;
    opacity: 0.8;
    margin-bottom: 10px;
  }
}
.image {
  object-fit: contain;
  width: 100%;
  border-radius: 16px;
}
.detail-advertisement {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 70px 10px 80px;

  & > div {
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    align-items: center;
    & > span {
      color: #494949;
      font-size: 1.4rem;
      line-height: 1.6;
    }
  }
}
//responsive

@media (max-width: 1024px) {
  .wrapper {
    overflow: hidden;
    padding: 0 0px 74px;
  }
  .detail-advertisement {
    padding: 10px 20px 10px 20px;
  }
  .container-advertisement {
    padding: 10px;
  }
}
@media (max-width: 768px) {
  .detail-advertisement {
    padding: 10px 00px 10px 00px;
  }
  .container {
    display: flex;
    flex-direction: column;
  }
  .container-image {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .image {
    object-fit: contain;
    margin: 2px auto;
    width: 40%;
    border-radius: 16px;
  }

  .advertisement-desktop {
    display: none;
  }
  .advertisement-mobile {
    display: block;
  }
  .container-advertisement {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 10px 10px;
  }
  .container-image {
    width: 50%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    gap: 15px;
    align-items: center;
  }
  .image {
    object-fit: contain;
    margin: 2px auto;
    width: 70%;
    border-radius: 16px;
  }
}
@media (max-width: 576px) {
  .benefits {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    flex: 1;
    gap: 8px;
  }
  .benefits-list {
    gap: 8px;
  }
  .image {
    width: 100%;
    border-radius: 8px;
    margin-left: -5px;
    & ~ div {
      margin-left: -5px;
    }
  }
  .detail-advertisement {
    margin-top: 10px;
    gap: 0px;
  }
  .program {
    flex-direction: column;
    align-items: flex-start;
  }
  .document {
    margin-top: 20px;
  }
  .document {
    & > div {
      & > h2 {
        margin: 15px 0 4px;
      }
    }
  }
  .basic-course {
    padding: 10px 12px;
    min-height: 65px;
  }
  .program {
    margin: 10px 0 10px 0;
  }
}
