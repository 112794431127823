.content-slider {
  position: relative;
}
.slider {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 250px;
  border-radius: 10px;
  overflow: hidden;
}
.slides {
  display: flex;
  width: 400%;
  height: 250px;
}
.contain-slide {
  display: flex;
  flex-shrink: 0;
  width: 25%;
  position: relative;
  border-radius: 10px;
  transition: 2.5s;
}
#radio1:checked ~ .contain-slide-first {
  margin-left: 0;
}

#radio2:checked ~ .contain-slide-first {
  margin-left: -25%;
}

#radio3:checked ~ .contain-slide-first {
  margin-left: -50%;
}

#radio4:checked ~ .contain-slide-first {
  margin-left: -75%;
}
.slide {
  position: relative;
  display: flex;
  width: 33.33%;
  transition: 1.5s;
  border-radius: 10px;
  margin-right: 10px;
  padding: 20px 25px 20px 25px;
  &:hover {
    position: relative;
  }
  animation: fadeIn linear 1s;
}

.slides input {
  display: none;
}
.first {
  background: linear-gradient(to right, rgb(36, 76, 222), rgb(30, 152, 227));
}
.second {
  background: linear-gradient(to right, rgb(237, 56, 56), rgb(244, 126, 126));
}

.third {
  background: linear-gradient(to right, rgb(237, 187, 79), rgb(235, 203, 140));
}
.first1 {
  background: linear-gradient(to right, #654ea3, #eaafc8);
}
.second1 {
  background: linear-gradient(to right, #ee4649, #ff9966);
}

.third1 {
  background: linear-gradient(to right, #1e2896, #004e92);
}
.display-mobile {
  padding: 20px 50px 10px 50px;
  display: none;
}
.display-mobile > div:nth-child(1) {
  flex: 3;
}
.display-mobile > div:nth-child(2) {
  display: flex;
  align-items: center;
  flex: 2;
}
.display-mobile > div > h3 {
  color: #fff;
}
.display-mobile > div > span {
  color: #fff;
}
.display-mobile > div > div {
  margin-top: 20px;
}
.display-mobile > div > img {
  width: 100%;
  height: auto;
}
.display {
  display: block;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.slide.first {
  margin-left: 5px;
}

.slide img {
  display: block;
  position: absolute;
  width: 55%;
  margin-top: 20px;
  padding: 70px 0 30px 0;
  right: 10px;
  object-fit: contain;
  height: 210px;
}
.slide h3 {
  color: #fff;
  margin-bottom: 10px;
  margin-top: 15px;
}
.bt {
  margin-top: 25px;
}
.feedback {
  margin-top: 7px;
}
.slide span {
  color: #fff;
  padding: 0 100px 0 0;
  font-size: 1.4rem;

  line-height: 1.6;
}

/*css for manual slide navigation*/

.navigation-manual {
  position: absolute;
  width: 100%;
  left: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}

.manual-btn {
  width: 32px;
  border-radius: 2px;
  background-color: #dce0e3;
  border-radius: 4px;
  height: 8px;
  margin-right: 10px;
  cursor: pointer;
  transition: 1s;
}

.manual-btn:not(:last-child) {
  margin-right: 10px;
}

.manual-btn:hover {
  cursor: pointer;
}
.display-mobile.slide1 {
  background: linear-gradient(to right, rgb(36, 76, 222), rgb(30, 152, 227));
}
.display-mobile.slide2 {
  background: linear-gradient(to right, rgb(237, 56, 56), rgb(244, 126, 126));
}
.display-mobile.slide3 {
  background: linear-gradient(to right, rgb(237, 187, 79), rgb(235, 203, 140));
}
.display-mobile.slide4 {
  background: linear-gradient(to right, #3341d3, #3c8acf);
}
.display-mobile-bt {
  display: none;
}
/* .bt-mobile {
  margin-top: 0;
  margin-bottom: 10px;
} */
/*css for automatic navigation*/

.active {
  background-color: #9aa6af;
  width: 50px;
  opacity: 0.75;
}
.bt-down {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateX(-50%);
  transform: translateY(-50%);
  margin-left: -16px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: #4b4b4b;
  cursor: pointer;
  font-size: 1.2rem;
  height: 32px;
  line-height: 32px;
  text-align: center;
  width: 32px;
  z-index: 1;
  &:active {
    background-color: #e8ebed;
  }
  transition: background-color ease 0.2s;
}
.bt-up {
  position: absolute;
  right: 0;
  top: 50%;
  margin-right: -16px;
  transform: translateY(-50%);
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: #4b4b4b;
  cursor: pointer;
  font-size: 1.2rem;
  height: 32px;
  line-height: 32px;
  text-align: center;
  width: 32px;
  z-index: 1;
  &:active {
    background-color: #e8ebed;
  }
  transition: background-color ease 0.2s;
}
.star {
  margin-top: 10px;
  display: flex;
  gap: 8px;
  color: #e3e620;
  font-size: 1.6rem;
}
.slide img.img-avatar {
  width: 90px;
  height: 90px;
  margin-top: 120px;
  margin-right: 60px;
  border-radius: 200px;
  padding: 0 0 0 0;
  border: 1px solid #fff;
}
/* //responsive */
@media (max-width: 1300px) {
  .slide img {
    width: 45%;
    height: 210px;
    margin-top: 37px;
    padding: 70px 0 30px 0;
  }
}
@media (max-width: 1200px) {
}
@media (max-width: 1024px) {
  .navigation-manual {
    left: 0;
  }
  .display-mobile {
    display: flex;
  }
  .display {
    display: none;
  }
  .display-mobile {
    background-color: #e8ebed;
    width: 100%;
  }
  .display-mobile-bt {
    display: block;
  }
  .slide.first {
    margin-left: 0px;
  }
}

@media (max-width: 768px) {
}
@media (max-width: 576px) {
  .slider {
    height: 170px;
  }
  .manual-btn {
    width: 24px;
    border-radius: 3px;
    height: 6px;
    margin-right: 7px;
    transition: 1s;
  }
  .active {
    background-color: #9aa6af;
    width: 32px;
    opacity: 0.75;
  }
  .manual-btn:not(:last-child) {
    margin-right: 5px;
  }
  /* mobile */
  .display-mobile {
    padding: 5px 20px 10px 30px;
  }
  .display-mobile > div:nth-child(1) {
    flex: 3;
  }
  .display-mobile > div:nth-child(2) {
    display: none;
    align-items: center;
    flex: 2;
  }
  .display-mobile > div > h3 {
    color: #fff;
    margin: 15px 0 5px 0;
  }
  .display-mobile > div > span {
    color: #fff;
    font-size: 1.2rem;
  }
  .display-mobile > div > div {
    margin-top: 10px;
    font-size: 1rem;
  }
  .display-mobile > div > img {
    width: 100%;
    height: auto;
  }
}
