.wrapper {
  overflow: hidden;
  padding: 0 20px 174px 10px;
}
.container {
  display: flex;
  & > div:first-child {
    flex: 2;
  }
  & > div:last-child {
    margin-top: 5px;
    flex: 0.9;
  }
}
.course-heading {
  & > h2 {
    color: #242424;
    font-size: 2.4rem;
    font-weight: 900;
    margin-right: auto;
    margin-top: 16px;
    & img {
      height: 30px;
      width: 25px;
      top: 2px;
      right: 0px;
      position: relative;
    }
    & span {
      & span {
        background-color: #f05123;
        border-radius: 4px;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 500;
        padding: 3px 6px;
        position: relative;
        right: -8px;
        text-transform: uppercase;
        top: -6px;
      }
    }
  }
}
.course-list {
  display: flex;
  margin-left: -12px;
  flex-wrap: wrap;
  padding-bottom: 100px;

  & > div {
    width: 25%;
    padding: 4px 12px 4px 12px;
    margin-bottom: 20px;
  }
}
.pagination {
  display: none;
}
.link-intro {
  padding: 20px 20px;
  h3 {
    color: #757575;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.8;
    text-transform: uppercase;
  }
  & > div {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
}
.intro {
  & > h1 {
    font-size: 3.2rem;
    font-weight: 700;
    margin-top: 8px;
    min-height: 33px;
  }
  & > span {
    color: rgba(0, 0, 0, 0.8);
    font-size: 1.4rem;
    line-height: 1.6;
  }
  & > div {
    & > h2 {
      font-size: 2rem;
      font-weight: 700;
      margin: 36px 0 4px;
    }
  }
}
.benefits {
  display: flex;
  margin-top: 10px;
  flex: 1;
}
.k23 {
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.4rem;
  line-height: 1.6;
  margin-bottom: 10px;
  & > b {
    color: var(--primary-color);
  }
  & > a {
    color: var(--primary-color);
  }
}
.benefits-list {
  display: flex;
  flex: 1;
  gap: 8px;
  flex-direction: column;
  align-items: flex-start;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    color: var(--primary-color);
    & > span {
      font-size: 1.4rem;
      line-height: 1.6;
      color: #333;
    }
  }
}
.member {
  & > h2 {
    font-size: 2rem;
    font-weight: 700;
    margin: 20px 0 4px;
  }
  & > div {
    margin-top: 10px;
    display: flex;
    color: var(--text-color);
    & > div {
      width: 25%;
      display: flex;
      align-items: center;
      gap: 5px;
      & > span {
        font-size: 1.4rem;
        line-height: 1.6;
        color: var(--text-color);
      }
    }
  }
}
.document {
  & > div {
    & > h2 {
      font-size: 2rem;
      font-weight: 700;
      margin: 36px 0 4px;
    }
  }
}

.basic-course {
  margin-top: 12px;
  background: #f5f5f5;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  padding: 0;
  top: 66px;
  padding: 12px 20px 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  & > span {
    font-size: 1.3rem;
    text-align: center;
  }
  & > div {
    display: flex;
    align-items: center;
    & > span {
      color: var(--text-color);
      font-size: 1.6rem;
      font-weight: 600;
      overflow: hidden;
      position: relative;
      display: block;
      margin-left: 10px;
    }
  }
  &:hover {
    cursor: pointer;
  }
}

.program {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 5px 0;
  & > span {
    color: rgba(0, 0, 0, 0.8);
    font-size: 1.4rem;
    line-height: 1.6;
    & > span {
      color: var(--primary-color);
    }
  }

  & > a {
    color: var(--primary-color);
    font-size: 1.4rem;
    line-height: 1.6;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
.advertisement-desktop {
  display: block;
}
.advertisement-mobile {
  display: none;
}
.container-advertisement {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px 20px;
  & > h5 {
    color: var(--primary-color);
    font-size: 32px;
    font-weight: 400;
    margin: 0 auto;
    opacity: 0.8;
    margin-bottom: 10px;
  }
}
.image {
  object-fit: contain;
  width: 100%;
  border-radius: 16px;
}
.detail-advertisement {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 50px 10px 50px;
  & > div {
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    align-items: center;
    & > span {
      color: #494949;
      font-size: 1.4rem;
      line-height: 1.6;
    }
  }
}
.popupModal {
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 1;
  display: none;
}
.active_con {
  display: block;
}
//popup
/* Google Fonts - Poppins */

.h {
  margin: 0;
  padding: 2px 0;
}
.section {
  position: fixed;
  background: #e3f2fd;
}
.button {
  font-weight: 400;
  color: #fff;
  margin: 5px 10px;
  margin-top: 15px;
  width: 90px;
  border: none;
  background: var(--primary-color);
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  padding: 7px 12px;
  margin: 0 10px;
}
.close-btn {
  //background-color: rgba(255, 127, 119);
  background-color: var(--primary-color);
}
.agree-btn {
  //background: rgba(131, 179, 154);
  background-color: var(--primary-color);
}
.show-modal {
  width: 89px;
  height: 40px;
  background: rgba(255, 127, 119);
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.modal-box {
  position: fixed;
  z-index: 999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  span {
    text-align: center;
    height: 30px;
    line-height: 30px;
    color: #fff;
    display: block;
    width: 100%;
  }
}

.overlay {
  position: fixed;
  z-index: 1003;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  opacity: 0;
  pointer-events: none;
}
.section.active .overlay {
  opacity: 1;
  pointer-events: auto;
}
.modal-box {
  display: flex;
  z-index: 1005;
  flex-direction: column;
  align-items: center;
  max-width: 380px;
  width: 100%;
  padding: 30px 20px;
  border-radius: 24px;
  background-color: #fff;
  color: var(--primary-color);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
  transform: translate(-50%, -50%) scale(1.2);
}
.section.active .modal-box {
  opacity: 1;
  pointer-events: auto;
  transform: translate(-50%, -50%) scale(1);
}
.modal-box i {
  font-size: 70px;
  color: var(--primary-color);
}
.modal-box h2 {
  margin-top: 15px;
  font-size: 2.2rem;
  font-weight: 500;
  color: #333;
}
.modal-box h3 {
  margin-top: 5px;
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 25px;
  color: #333;
  text-align: center;
}
.buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
}
.icon {
  font-size: 1.4rem;
  color: var(--primary-color);
}

//responsive

@media (max-width: 1024px) {
  .detail-advertisement {
    padding: 10px 20px 10px 20px;
  }
  .course-list {
    margin-left: -8px;
    & > div {
      width: 33.33%;
      padding: 4px 8px 4px 8px;
      margin-bottom: 15px;
    }
  }
  .wrapper {
    overflow: hidden;
    padding: 0 0px 74px;
  }
  .member {
    & > div {
      flex-wrap: wrap;
      & > div {
        width: 50%;
      }
    }
  }
  .member-detail {
    width: 50%;
  }
  .container-advertisement {
    padding: 10px;
  }
}
@media (max-width: 768px) {
  .detail-advertisement {
    padding: 10px 00px 10px 00px;
  }
  .course-list {
    margin-left: -8px;
    padding-bottom: 0;
    & > div {
      width: 50%;
      padding: 4px 8px 4px 8px;
      margin-bottom: 15px;
    }
  }
  .pagination {
    display: block;
    padding-bottom: 50px;
  }
  .container {
    display: flex;
    flex-direction: column;
  }
  .container-image {
    width: 100%;
    display: flex;
    justify-content: center;

    & > span {
      margin: 0;
      margin-top: -7px;
      margin-bottom: 2px;
      color: var(--primary-color);
    }
  }
  .image {
    object-fit: contain;
    margin: 2px auto;
    width: 40%;
    border-radius: 16px;
  }

  .advertisement-desktop {
    display: none;
  }
  .advertisement-mobile {
    display: block;
  }
  .container-advertisement {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 10px 10px;
  }
  .container-image {
    width: 50%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
  }
  .image {
    object-fit: contain;
    margin: 2px auto;
    width: 70%;
    border-radius: 16px;
  }
}
@media (max-width: 576px) {
  .benefits {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    flex: 1;
    gap: 8px;
  }
  .benefits-list {
    gap: 8px;
  }
  .h5_mobile {
    margin: 0;
    margin-top: -7px;
    margin-bottom: 2px;
    color: var(--primary-color);
  }

  .image {
    width: 100%;
    border-radius: 8px;
    margin-left: -5px;
    & ~ div {
      margin-left: -5px;
    }
  }
  .detail-advertisement {
    margin-top: 10px;
    gap: 0px;
  }
  .course-list {
    margin-left: -4px;

    & > div {
      width: 50%;
      padding: 4px 4px 4px 4px;
      margin-bottom: 12px;
    }
  }
  .program {
    flex-direction: column;
    align-items: flex-start;
  }
  .document {
    margin-top: 20px;
  }
  .document {
    & > div {
      & > h2 {
        margin: 15px 0 4px;
      }
    }
  }
  .basic-course {
    padding: 10px 12px;
    min-height: 65px;
  }
  .modal-box h2 {
    margin-top: 5px;
    font-size: 2rem;
    font-weight: 500;
    color: #333;
  }
  .modal-box h3 {
    margin-top: 5px;
    font-size: 1.4rem;
    margin-bottom: 12px;
  }
  .buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
  }
  .modal-box {
    max-width: 380px;
    width: 80%;
    padding: 20px 10px;
  }
  .program {
    margin: 10px 0 10px 0;
  }
}
