.wrapper {
  position: fixed;
  bottom: 0;
  right: 50px;
  width: 330px;
  font-size: 1.4rem;
  height: 455px;
  z-index: 25;
}
.bt-open {
  z-index: 25;
  position: relative;
  & > img {
    z-index: 10;
  }
}
.circle-big {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75px;
  height: 75px;
  transform: translate(-50%, -50%);
  z-index: 3;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.circle-big-1 {
  position: absolute;
  // bottom: -15px;
  // right: -15px;
  width: 97px;
  height: 97px;
  z-index: 2;
  border-radius: 50%;
  background-color: #33375d;
  animation: buble 2s infinite;
}

@keyframes buble {
  0% {
    opacity: 1;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
.Container {
  height: 100%;
}
.bt-close {
  font-size: 2.6rem;
  color: #6ea9d7;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
}

.bt-open {
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  position: fixed;
  left: 15px;
  height: 70px;
  width: 70px;
  bottom: 80px;
  background-color: #fff;

  & > img {
    width: 100%;
  }

  &:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 15px 15px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px 2px 5px;
  }
  transition: all 0.8s ease;
  animation: fadeIn 2s infinite linear;
}
.menu-title {
  font-size: 1.1rem;
  font-weight: 600;
  z-index: 10;
  color: #4758ff;
  cursor: pointer;
}
@keyframes fadeIn {
  0% {
    transform: translateY(-0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
//responsive

@media (max-width: 1024px) {
}
@media (max-width: 768px) {
}
@media (max-width: 576px) {
  .wrapper {
    width: 100%;
    left: 0px;
    top: 0;
    bottom: 0;
    height: 100%;
  }
  .bt-open {
    height: 65px;
    width: 65px;
  }
}
