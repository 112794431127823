.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 10px;

  & div {
    background-color: #fff;
    border-radius: 10rem;
    height: 12px;
    width: 12px;
    animation: stagger 0.5s linear infinite alternate;
  }
  & div:nth-child(1) {
    animation-delay: -0.125s;
  }
  & div:nth-child(2) {
    animation-delay: -0.25s;
  }
  & div:nth-child(3) {
    animation-delay: -0.375s;
  }
  & div:nth-child(4) {
    animation-delay: -0.5s;
  }
}
@keyframes stagger {
  0% {
    transform: translateY(-15px);
  }

  50% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(-2px);
  }
}
@media (max-width: 576px) {
  .wrapper {
    & div {
      background-color: #8c6238;
      opacity: 0.3;
    }
  }
}
