.container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  height: 150px;
  min-height: 100vh;

  & div {
    height: 100px;
    width: 35vw;
    min-width: 200px;
    display: inline-block;
  }
  & div:nth-child(5) {
    background-color: red;
  }
}
.containerx {
  display: flex;
  & > div:last-child {
    flex: 1;
  }
}

.page {
  overflow: hidden;
  padding: 20px 35px 0 35px;
  margin-left: 100px;
}

// responsive
@media (max-width: 1024px) {
  .page {
    padding: 20px 35px 0 35px;
    margin-left: 0;
  }
}
@media (max-width: 768px) {
  .page {
    padding: 15px 30px 0 30px;
    margin-left: 0;
  }
}
@media (max-width: 576px) {
  .page {
    padding: 15px 15px 0 15px;
  }
}
