.container {
  background-color: #fff;
  position: fixed;
  width: 50%;
  top: 0;
  bottom: 0;
  z-index: 20;
  transform: translateX(-100%);
  opacity: 0;
  overflow: hidden;
  transition: transform ease 0.5s, opacity ease 0.5s;
}
.container_active {
  transform: translateX(0%);
  opacity: 1;
}
.wrapper {
  margin-top: 86px;
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: flex-start;
  flex-direction: column;
}
.layout {
  animation: fadeIn ease 0.5s, fadeOut ease 0.5s;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.seperater {
  border-bottom: 1px solid rgba(0, 0, 0, 0.079);
  font-size: 1.6rem;
  width: 100%;
  padding: 16px 0;
  margin-bottom: 10px;
}
.active {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.3);
}

//responsive

@media (max-width: 1024px) {
  .container {
    width: 70%;
  }
}
@media (max-width: 768px) {
  .container {
    width: 80%;
  }
}
@media (max-width: 576px) {
}
