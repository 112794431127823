.link {
  background-color: var(--primary-color);
  border-radius: 999px;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 9px 20px;

  display: inline-block;
}
.button {
  position: relative;
}

.ani {
  position: absolute;
  top: 50%;
  transform: translateX(-50%);
  left: 50%;
}
.width {
  width: 110px;
  text-align: center;
}
.height {
  height: 40px;
  padding: 11px 20px 9px;
}
.link:hover {
  opacity: 0.8;
  transition: opacity ease 0.5s;
}
.story {
  background-color: transparent;
  border: 1px solid #fff;
  &:hover {
    opacity: 1;
    background: #fff;
    transition: background ease 0.5s;
    color: var(--primary-color);
  }
}
.cal {
  padding: 10px 160px;
  font-size: 1.7rem;
}
.pt5 {
  background-color: #f2f2f2;
  color: #333;
}
.opacityText {
  color: var(--primary-color);
}
@media (max-width: 768px) {
  .cal {
    padding: 9px 120px;
    font-size: 1.6rem;
  }
}
@media (max-width: 576px) {
  .cal {
    padding: 9px 100px;
    font-size: 1.6rem;
  }
}
