.container {
  position: relative;
  z-index: 0;
}
.wrapper {
  border-radius: 6px;
  text-decoration: none;
  color: var(--text-color);
  padding: 0;

  padding: 12px 20px 12px 40px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  & > span {
    font-size: 1.3rem;
  }
  & > div {
    & > span {
      color: var(--text-color);
      font-size: 1.3rem;
      overflow: hidden;
      position: relative;
      margin-left: 10px;
      font-weight: 500;
    }
  }
}
.icon {
  color: var(--primary-color);
  font-size: 1.4rem;
}
.active {
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
  }
  transition: all ease 0.5s;
}
.subject-item {
  padding: 0 0 0 20px;
}
//responsive

@media (max-width: 1024px) {
}
@media (max-width: 768px) {
}
@media (max-width: 576px) {
  .wrapper {
    padding: 10px 30px;
  }
}
