// .wrapper {
// }
// .container-image {
//   display: block;
//   position: relative;
//   overflow: hidden;
// }
// .image {
//   object-fit: contain;
//   width: 100%;
//   border-radius: 16px;
// }
// .image-active {
//   position: absolute;
//   cursor: pointer;
//   top: 0;
//   left: 0;
//   bottom: 3px;
//   right: 0;
//   border-radius: 16px;
//   &:hover {
//     background-color: rgba(0, 0, 0, 0.5);
//     transition: all 0.3s ease 0s;
//     & > button {
//       opacity: 1;
//       border: 0;
//       display: inline-block;
//       transform: translate(-50%, -50%);
//     }
//   }
// }
// .bt-course {
//   background-color: #fff;
//   border-color: #fff;
//   color: #000;
//   position: absolute;
//   opacity: 0;
//   left: 50%;
//   transform: translate(-50%, 0);
//   top: 50%;
//   cursor: pointer;
//   transition: all 0.5s ease 0s;
//   border-radius: 999px !important;
//   z-index: 1;
//   font-size: 1.4rem;
//   font-weight: 600;
//   outline: none;
//   padding: 9px 16px;
//   text-align: center;
// }
// .star-container {
//   background-color: rgba(0, 0, 0, 0.3);
//   border-radius: 8px;
//   left: 12px;
//   padding: 5px;
//   pointer-events: none;
//   position: absolute;
//   top: 12px;
//   width: 26px;
// }
// .star {
//   height: 16px;
//   width: 16px;
// }
// .name-course {
//   word-wrap: break-word;
//   -webkit-box-orient: vertical;
//   -webkit-line-clamp: 1;
//   color: #292929;
//   display: -webkit-box;
//   font-size: 1.8rem;
//   font-weight: 600;
//   line-height: 1.4;
//   overflow: hidden;
// }
// .name-title {
//   margin: 11px 0 0px 0;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   color: #666;
//   & > span {
//     margin-right: 20px;
//   }
// }
// .price {
//   & > span:first-child {
//     font-size: 1.4rem;
//     margin-right: 8px;
//     text-decoration: line-through;
//   }
//   & > span:nth-child(2) {
//     color: var(--primary-color);
//     font-size: 1.6rem;
//     font-weight: 600;
//   }
// }
// /* //responsive */

// @media (max-width: 1024px) {
// }

// @media (max-width: 768px) {
// }
// @media (max-width: 576px) {
//   .slider {
//     height: 170px;
//   }
//   .name-course {
//     font-size: 1.4rem;
//     font-weight: 500;
//     line-height: 1.4;
//   }
//   .name-title {
//     margin: 5px 0 0px 0;
//   }
//   .price {
//     & > span:first-child {
//       font-size: 1.2rem;
//       margin-right: 8px;
//     }
//     & > span:nth-child(2) {
//       font-size: 1.4rem;
//       font-weight: 600;
//     }
//   }
//   .star-container {
//     left: 8px;
//     padding: 0px 2px 2px 3px;
//     top: 8px;
//     width: 18px;
//     border-radius: 7px;
//   }
//   .star {
//     height: 12px;
//     width: 12px;
//   }
//   .bt-course {
//     font-size: 1.2rem;
//     font-weight: 400;
//     padding: 5px 7px;
//   }
//   .image-active {
//     border-radius: 10px;
//   }
//   .image {
//     border-radius: 10px;
//   }
// }
// kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk
.wrapper {
}
.container-image {
  height: 175px;
  display: block;
  position: relative;
  border-radius: 16px;
  cursor: auto;
}
.image {
  height: 175px;
  object-fit: cover;
  width: 100%;
  border-radius: 16px;
}
.image-active {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  bottom: 1px;
  right: 0;
  border-radius: 16px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease 0s;
    & > button {
      opacity: 1;
      border: 0;
      display: inline-block;
      transform: translate(-50%, -50%);
    }
  }
}
.bt-course {
  background-color: #fff;
  border-color: #fff;
  color: #000;
  position: absolute;
  opacity: 0;
  left: 50%;
  transform: translate(-50%, 0);
  top: 50%;
  cursor: pointer;
  transition: all 0.5s ease 0s;
  border-radius: 999px !important;
  z-index: 1;
  font-size: 1.4rem;
  font-weight: 600;
  outline: none;
  padding: 9px 16px;
  text-align: center;
}
.star-container {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  left: 12px;
  padding: 4px 6px 4px 4px;
  pointer-events: none;
  position: absolute;
  top: 12px;
  width: 26px;
}
.star {
  height: 15px;
  width: 18px;
}
.name-course {
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: #292929;
  display: -webkit-box;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.4;
  overflow: hidden;
}
.name-title {
  margin: 11px 0 0px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666;
  & > span {
    margin-right: 20px;
  }
}
.price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    & > span:first-child {
      font-size: 1.4rem;
      margin-right: 8px;
      text-decoration: line-through;
    }
    & > span:nth-child(2) {
      color: var(--primary-color);
      font-size: 1.6rem;
      font-weight: 600;
    }
  }

  & > span:nth-child(3) {
    color: var(--primary-color);
    font-size: 1.2rem;
    font-weight: 600;
  }
}
.note {
  font-size: 1.2rem;
}
/* //responsive */
@media (max-width: 1200px) {
  .container-image {
    height: 150px;
  }
  .image {
    height: 150px;
  }
}
@media (max-width: 1024px) {
  .container-image {
    height: 170px;
  }
  .image {
    height: 170px;
  }
}
@media (max-width: 850px) {
  .container-image {
    height: 150px;
  }
  .image {
    height: 150px;
  }
}

@media (max-width: 768px) {
  .container-image {
    height: 165px;
  }
  .image {
    height: 165px;
  }
}
@media (max-width: 650px) {
  .container-image {
    height: 155px;
  }
  .image {
    height: 155px;
  }
}
@media (max-width: 576px) {
  .container-image {
    height: 135px;
  }
  .image {
    height: 135px;
  }
  .slider {
    height: 170px;
  }
  .name-course {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.4;
  }

  .name-title {
    margin: 5px 0 0px 0;
  }
  .price {
    & > span:first-child {
      font-size: 1.2rem;
      margin-right: 8px;
    }
    & > span:nth-child(2) {
      font-size: 1.4rem;
      font-weight: 600;
    }
  }
  .star-container {
    left: 8px;
    padding: 0px 2px 2px 3px;
    top: 8px;
    width: 18px;
    border-radius: 7px;
  }
  .star {
    height: 12px;
    width: 12px;
  }
  .bt-course {
    font-size: 1.2rem;
    font-weight: 400;
    padding: 5px 7px;
  }
  .image-active {
    border-radius: 10px;
  }
  .image {
    border-radius: 10px;
  }
}
@media (max-width: 476px) {
  .container-image {
    height: 120px;
  }
  .image {
    height: 120px;
  }
}
@media (max-width: 426px) {
  .container-image {
    height: 105px;
  }
  .image {
    height: 105px;
  }
  .note {
    display: block;
  }
}
