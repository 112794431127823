.container {
  --height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e8ebed;
  font-size: 1.4rem;
  height: var(--height);
  left: 0;
  padding: 0 28px;
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  top: 0;
  z-index: 2;
}
.link_all {
  text-decoration: none;
  color: var(--text-color);
}
.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.menu {
  display: none;
}
.group-title {
  color: #000;
  font-size: 1.4rem;
  font-weight: 700;
  margin-left: 16px;
}
.image-logo {
  flex-shrink: 0;
  height: 38px;
  object-fit: contain;
  width: 38px;
  border-radius: 10px;
}
.bell {
  position: relative;
  cursor: pointer;
  &:hover {
    color: #5a4b4b;
  }
  &::before {
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    top: 3px;
    right: 2px;
    background-color: #ff0000;
    border-radius: 50%;
    animation: fadeIn 1s infinite ease;
  }
  &::after {
    content: "";
    width: 4px;
    height: 4px;
    position: absolute;
    top: 3px;
    right: 3.2px;
    background-color: #e67b7b;
    border-radius: 50%;
    animation: fadeIn1 1s infinite ease;
  }
}
.infor {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 0 0 0;
  font-size: 1.1rem;
  margin-bottom: 6px;
}
@keyframes fadeIn1 {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(2);
    opacity: 0;
  }
}
@keyframes fadeIn {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(5.5);
    opacity: 0;
  }
}
.language {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
  & > img {
    width: 18px;
    height: 18px;
  }
  & > span {
    color: #333;
    font-size: 1.4rem;
    font-weight: 600;
    margin-right: 6px;
    padding: 8px;
  }
}
.detail {
  display: flex;
  align-items: center;
}
.infor {
  height: 45px;
  display: flex;
  margin-left: 10px;
  align-items: center;
  gap: 16px;
  color: #707070;
  font-size: 2rem;
  & > img {
    border-radius: 50%;
    height: 32px;
    width: 32px;
    object-fit: cover;
  }
}
//responsive

@media (max-width: 1024px) {
  .container {
    padding: 0 16px;
  }
  .logo {
    display: none;
  }
  .menu {
    display: block;
  }
}
@media (max-width: 768px) {
  .container {
    padding: 0 16px;
  }
  .language {
    display: none;
  }
}

@media (max-width: 576px) {
  // .box {
  //   display: none;
  // }
  .menu {
    flex: 1;
  }
  .language {
    display: none;
  }
}
