.wrapper {
  padding: 10px 10px;
  background-color: rgba(167, 167, 160);
  max-width: 270px;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: fixed;
  bottom: -150px;
  right: 100px;
  z-index: 20;
  & > span {
    color: #333;
    text-align: center;
  }
  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid rgba(167, 167, 160);
  }
  //animation: fadeIn 5s linear;
}
.animation {
  animation: fadeIn 10s linear;
}
@keyframes fadeIn {
  0% {
    bottom: -40px;
    opacity: 1;
  }
  50% {
    opacity: 0.9;
  }
  90% {
    opacity: 0.8;
  }
  100% {
    bottom: 900px;
    opacity: 0;
  }
}
@media (max-width: 756px) {
  .wrapper {
    width: 50%;
    right: 50px;
  }
}
@media (max-width: 576px) {
  .wrapper {
    width: 75%;
    right: 20px;
  }
}
