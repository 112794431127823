.menu-item {
  font-size: 1.4rem;
  line-height: 20px;
  height: 60px;
  margin-left: 32px;
  display: flex;
  align-items: center;
  width: 95%;
  font-weight: 400;
  text-decoration: none;
  color: #757575;
  padding: 20px 0 20px 20px;
  border-radius: 5px;
  &:hover {
    background-color: #f0f0f0;
    color: #292929;
  }
  transition: background-color ease 0.5s;
}

.menu-title {
  cursor: pointer;
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  text-decoration: none;
  padding: 20px 0 20px 16px;
  transition: 0.3s;
  color: #757575;
}
.active {
  background-color: #f0f0f0;
  color: #292929;
}
//responsive

@media (max-width: 1024px) {
  .menu-item {
    margin-left: 24px;
  }
}
@media (max-width: 768px) {
}
@media (max-width: 576px) {
  .menu-item {
    margin-left: 16px;
  }
}
