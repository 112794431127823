.popupModal {
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 1;
  display: none;
}

.h {
  margin: 0;
  padding: 2px 30px;
  text-align: start;
  width: 100%;
}
.section {
  position: fixed;
  background: #e3f2fd;
}
.button {
  font-weight: 400;
  color: #fff;
  margin: 5px 10px;
  margin-top: 15px;
  width: 90px;
  border: none;
  background: var(--primary-color);
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  padding: 7px 12px;
  margin: 0 10px;
}

.show-modal {
  width: 89px;
  height: 40px;
  background: rgba(255, 127, 119);
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.modal-box {
  position: fixed;
  z-index: 999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  span {
    text-align: center;
    height: 30px;
    line-height: 30px;
    color: #fff;
    display: block;
    width: 100%;
  }
}

.overlay {
  position: fixed;
  z-index: 1003;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  opacity: 0;
  pointer-events: none;
}
.section.active .overlay {
  opacity: 1;
  pointer-events: auto;
}
.modal-box {
  display: flex;
  z-index: 1005;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  width: 100%;
  padding: 5px 30px 30px;
  border-radius: 24px;
  background-color: #fff;
  color: var(--primary-color);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
  transform: translate(-50%, -50%) scale(1.2);
}
.header-title {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 15px;
  gap: 10px;
  & > svg {
    width: 24px;
    height: 24px;
    margin-top: 2px;
    color: #333;
  }
  & > h2 {
  }
}
.section.active .modal-box {
  opacity: 1;
  pointer-events: auto;
  transform: translate(-50%, -50%) scale(1);
}
.modal-box i {
  font-size: 70px;
  color: var(--primary-color);
}
.modal-box h2 {
  font-size: 2.2rem;
  font-weight: 500;
  color: #333;
}
.modal-box h3 {
  margin-top: 5px;
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 25px;
  color: #333;
  text-align: center;
}
.active_con {
  display: block;
}
.img {
  width: 350px;
  height: auto;
  border-radius: 20px;
}
.displaydesktop {
  display: block;
}
.displaymobile {
  display: none;
}
.container-close {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.icon {
  font-size: 2rem;
  cursor: pointer;
  color: #333;
  &:hover {
    opacity: 0.8;
  }
}
//responsive

@media (max-width: 1024px) {
}
@media (max-width: 768px) {
  .img {
    width: 300px;
    height: auto;
    border-radius: 20px;
  }
  .modal-box {
    max-width: 550px;

    width: 100%;
  }
  .h {
    margin: 0;
    padding: 2px 0px;
  }
  .header-title {
    margin-bottom: 0;
  }
  .modal-box h3 {
    margin-bottom: 15px;
  }
}
@media (max-width: 576px) {
  .img {
    width: 200px;
  }
  .modal-box {
    max-width: 350px;
  }
  .h {
    margin: 0;
    padding: 2px 0px;
  }
  .header-title {
    margin-bottom: 0;
  }
  .modal-box h3 {
    margin-bottom: 5px;
  }
  .displaydesktop {
    display: none;
  }
  .displaymobile {
    display: block;
  }
  .modal-box {
    padding: 0px 30px 25px;
  }
}
