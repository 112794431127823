.container {
  font-family: var(--system-ui);
  font-size: 1.8 rem;
  display: flex;
  & > div:first-child {
    flex: 2;
  }
  & > div:last-child {
    margin-top: 5px;
    flex: 0.9;
  }
  padding-bottom: 250px;
}
.advertisement-desktop {
  display: block;
}
.container-advertisement {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px 20px;
  & > h5 {
    font-size: 1.6rem;
    font-weight: 300;
    text-align: center;
    margin: 0 auto;
    opacity: 0.8;
    margin-bottom: 10px;
  }
}
.Group {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}
.sub-group {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}
.hightline {
  color: #e11919;
}
.hightline-black {
  font-weight: bold;
}
.center-hightline {
  display: flex;
  justify-content: center;
  align-items: center;

  & > span {
    display: block;
  }
}
.link-intro {
  padding: 20px 20px;
  h3 {
    color: #757575;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.8;
    text-transform: uppercase;
  }
  & > div {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
}
.image {
  object-fit: contain;
  width: 100%;
  border-radius: 16px;
}
.logo-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 6px;
  text-decoration: none;
  cursor: pointer;
  & > h2 {
    font-size: 1.5rem;
    color: #494949;
    &:hover {
      color: #185e98;
    }
  }
}
.logo {
  border-radius: 8px;
  height: 38px;
  margin-right: 0px;
  width: 38px;
}
@media (max-width: 1024px) {
  .container-advertisement {
    padding: 10px;
  }
}
@media (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
  }
  .advertisement-desktop {
    display: none;
  }
  .container-advertisement {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 10px 10px;
  }

  .image {
    object-fit: contain;
    margin: 2px auto;
    width: 70%;
    border-radius: 16px;
  }
  .container-image {
    width: 100%;
    display: flex;
    justify-content: center;

    & > h5 {
      margin: 0;
      margin-top: -7px;
      margin-bottom: 2px;
      color: var(--primary-color);
    }
  }
  .container-image {
    width: 50%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
  }
}
@media (max-width: 576px) {
  .image {
    width: 100%;
    border-radius: 8px;
    margin-left: -5px;
    & ~ div {
      margin-left: -5px;
    }
  }
  .Group {
    flex-direction: column;
    justify-items: flex-start;
    align-items: flex-start;
    gap: 10px;
  }
}
