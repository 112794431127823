.wrapper {
  width: 420px;
  margin-top: 3px;
  background: rgb(255, 255, 255);
  max-height: min((100vh - 96px) - 60px, 734px);
  min-height: 100px;
  padding: 15px 24px;
  margin-left: 22px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  color: #1d2129;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  will-change: opacity, transform;
}
.infor {
  padding: 0px 24px 15px;
  width: 250px;
}
