.menu-item {
  text-align: center;
  text-decoration: none;
  flex-direction: column;
  display: flex;
  justify-content: center;
  background-color: #fff;
  align-items: center;
  border-radius: 16px;
  cursor: pointer;
  height: 72px;
  margin-top: 4px;
  width: 72px;
  &:hover {
    background-color: #f5f5f6;
    color: #1e1d1d;
  }
}
.menu-title {
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 6px;
  color: #404040;
  cursor: pointer;
}
.active {
  background-color: #e8ebee;
  color: "#1a1a1a";
}
