@import "normalize.css";
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;600;700&family=Poppins:wght@300;400;500;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,900&display=swap");
// --primary-color: #f05123;
//  --primary-color: #183153;
//  --primary-color: #d41717;
:root {
  --primary-color: #f69432;
  --black-color: #181821;
  --outstanding-color: #e96316;
  --white-color: #fff;
  --gray-color: #666;
  --text-color: #333;

  --system-ui: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
html {
  font-size: 62.5%;
}
body {
  font-family: var(--system-ui);
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow-y: overlay;
  overscroll-behavior: none;
  touch-action: manipulation;
  -webkit-user-drag: none;
  font-family: var(--system-ui);
  font-size: 1.6rem;
  margin: 0;
}

* {
  outline: 0;
  box-sizing: border-box;
  // transition: all 0.2s linear;
}
::-webkit-scrollbar {
  width: 7px;
  max-width: 12px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(171 171 171);
  height: 20%;
  border-radius: 8px;
}
::-webkit-scrollbar-track {
  background-color: #fafafa;
}
