.slider {
  margin: 0;
  padding: 0;
}
.wrapper {
  overflow: hidden;
  padding: 0 20px 174px 0;
}
.pagination {
  margin-top: -10px;
  margin-bottom: 30px;
}
.chuamo {
  font-size: 16px;
  font-weight: 300;
  margin: 0 0 0 5px;
  color: #f05123;
  font-style: italic;
}
.link-intro {
  padding: 20px 0;
  h3 {
    color: #757575;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.8;
    text-transform: uppercase;
  }
  & > div {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
}
.course-heading {
  & > h2 {
    color: #242424;
    font-size: 2.4rem;
    font-weight: 900;
    margin-right: auto;
    margin-top: 8px;
    & img {
      height: 30px;
      width: 25px;
      top: 2px;
      right: 0px;
      position: relative;
    }

    & span {
      & span {
        background-color: #1473e6;
        border-radius: 4px;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 500;
        padding: 3px 6px;
        position: relative;
        right: -8px;
        text-transform: uppercase;
        top: -6px;
      }
    }
  }
}
.course-list {
  display: flex;
  margin-left: -12px;
  flex-wrap: wrap;

  & > div {
    width: 25%;
    padding: 4px 12px 4px 12px;
    margin-bottom: 20px;
  }
}
.desktop {
  display: none;
}
//responsive

@media (max-width: 1024px) {
  .wrapper {
    overflow: hidden;
    padding: 0 0px 74px;
  }
  .course-list {
    margin-left: -8px;

    & > div {
      width: 33.33%;
      padding: 4px 8px 4px 8px;
      margin-bottom: 15px;
    }
  }
  .wrapper {
    overflow: hidden;
    padding: 0 0px 74px;
  }
}
@media (max-width: 768px) {
  .course-list {
    margin-left: -8px;

    & > div {
      width: 50%;
      padding: 4px 8px 4px 8px;
      margin-bottom: 15px;
    }
  }
}
@media (max-width: 576px) {
  .wrapper {
    overflow: hidden;
    padding: 0 0px 74px;
  }
  .course-list {
    margin-left: -4px;

    & > div {
      width: 50%;
      padding: 4px 4px 4px 4px;
      margin-bottom: 12px;
    }
  }
  .desktop {
    display: block;
  }
  .pagination {
    margin-top: 0;
    margin-bottom: 20px;
  }
}
