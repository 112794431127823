.wrapper {
  width: 100%;
  & > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
.link {
  transition: all ease 0.3s;
  align-items: center;
  height: 30px;
  display: flex;
  padding: 15px 0 15px 10px;
  border-radius: 7px;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.02);
  }
  & > div {
    background: transparent;
    border-radius: 50%;
    font-size: var(--font-size);
    & > img {
      border-radius: 50%;
      height: 25px;
      object-fit: cover;
      width: 25px;
    }
  }

  & > span {
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: #292929;
    display: -webkit-box;
    font-size: 1.4rem;
    line-height: 1.6;
    margin-left: 12px;
    overflow: hidden;
  }
}
.title-head {
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 15px 0 12px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;

  & > h5 {
    color: var(--text-color);
    font-size: 1.4rem;
    font-weight: 500;
    margin: 0;
  }
  & > a {
    color: var(--desc-color);
    text-decoration: none;
    font-size: 1.4rem;
    &:hover {
      color: var(--primary-color);
    }
    transition: all ease 0.5s;
  }
}
