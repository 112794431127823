.search {
  border: 2px solid #e8e8e8;
  border-radius: 20px;
  height: 40px;
  padding: 0 0px 0 8px;
  display: flex;
  transition: border-color 0.2s ease-in-out;
  width: 420px;
  &.focus {
    border-color: #807575;
  }

  & input {
    border-color: transparent;
    display: block;
    caret-color: #6b5c5c;
    height: 100%;
    flex-grow: 1;
    outline: none;
    padding: 0 4px;
    margin: 0 0 0 7px;
  }

  & button {
    border-radius: 0 20px 20px 0;
    border-color: transparent;
    caret-color: #615b5b;
    height: 100%;
    opacity: 0.7;
    transition: opacity 0.2s ease-in-out;
    width: 40px;
    justify-items: end;
    cursor: pointer;
  }
}
.search_result {
}

.bt-mobile {
  border: none;
  background-color: #fff;
  font-size: 2rem;
  margin-right: 5px;
}
.result {
  padding: 10px 0 5px;
}
.bt-contain {
  display: none;
}
.search-mobile {
  position: fixed;
  top: 66px;
  left: 0;

  right: 0;
}
@media (max-width: 1024px) {
}
@media (max-width: 768px) {
}
@media (max-width: 576px) {
  .bt-contain {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 40px;
  }
  .search {
    display: none;
  }
}
