.wrapper {
  border-radius: 6px;
  text-decoration: none;
  color: var(--text-color);
  padding: 0;

  padding: 12px 20px 12px 42px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  & > span {
    font-size: 1.3rem;
  }
  & > div {
    display: flex;
    align-items: center;
    & > span {
      color: var(--text-color);
      font-size: 1.3rem;
      overflow: hidden;
      position: relative;
      margin-left: 10px;

      padding: 0 50px 0 0;
    }
  }
}
.positive {
  position: relative;
  top: 0;
  z-index: 1000;
}
.free {
  color: rgb(31 179 34);
}
.active {
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.02);
  }
  transition: all ease 0.5s;
}
.icon {
  color: var(--primary-color);
  font-size: 0.8rem;
}
//responsive

@media (max-width: 1024px) {
}
@media (max-width: 768px) {
}
@media (max-width: 576px) {
  .wrapper {
    padding: 10px 30px;
  }
}
