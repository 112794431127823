.wrapper {
  max-width: 100px;
  margin-top: 82px;
  display: flex;
  gap: 3px;
  align-items: center;
  flex-direction: column;
  margin-left: 15px;
  position: fixed;
  top: 0;
  bottom: 50%;
}
//responsive

@media (max-width: 1024px) {
  .wrapper {
    display: none;
  }
}
@media (max-width: 768px) {
}
@media (max-width: 576px) {
}
