.container {
  display: flex;
  & > div:first-child {
    flex: 2;
  }
  & > div:last-child {
    margin-top: 5px;
    flex: 0.9;
  }
  padding-bottom: 250px;
}
.intro {
  & > h1 {
    font-size: 3.2rem;
    font-weight: 700;
    margin-top: 8px;
    min-height: 33px;
  }
  & > span {
    color: rgba(0, 0, 0, 0.8);
    font-size: 1.4rem;
    line-height: 1.6;
  }
  & > div {
    & > h2 {
      font-size: 2rem;
      font-weight: 700;
      margin: 10px 0 4px;
    }
  }
}

.document {
  & > div {
    & > h2 {
      font-size: 2rem;
      font-weight: 700;
      margin: 10px 0 4px;
    }
  }
}
.bonus {
  color: #17b314;
  font-weight: 600;
}
.press {
  color: var(--primary-color);
}
.basic-course {
  margin-top: 12px;
  background: #f5f5f5;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  padding: 0;
  top: 66px;
  padding: 12px 20px 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  & > span {
    font-size: 1.3rem;
    text-align: center;
  }
  & > div {
    display: flex;
    align-items: center;
    & > span {
      color: var(--text-color);
      font-size: 1.6rem;
      font-weight: 600;
      overflow: hidden;
      position: relative;
      display: block;
      margin-left: 10px;
    }
  }
  &:hover {
    cursor: pointer;
  }
}
.hightline {
  font-weight: bold;
}
.program {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  & > span {
    color: rgba(0, 0, 0, 0.8);
    font-size: 1.4rem;
    line-height: 1.6;
    & > span {
      color: var(--primary-color);
    }
  }

  & > a {
    color: var(--primary-color);
    font-size: 1.4rem;
    line-height: 1.6;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
.advertisement-desktop {
  display: block;
}

.score {
  margin-top: 10px;
  & > span {
    font-weight: 600;
    font-size: 1.9rem;
  }
  & > span.note {
    margin-top: 10px;
    font-weight: 200;
    font-size: 1.6rem;
    color: var(--primary-color);
  }
}
.link-intro {
  padding: 20px 20px;
  h3 {
    color: #757575;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.8;
    text-transform: uppercase;
  }
  & > div {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
}
.advertisement-mobile {
  display: none;
}
//input
.container-input-score {
  & > span {
    margin-top: 3px;
    display: block;
  }

  margin-top: 10px;
}
.input-score {
  margin-top: 7px;
  display: flex;
  gap: 7px;
  flex-wrap: wrap;
}
.title-score {
  & > img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
  & > span {
    font-weight: 500;
  }
}
.Group {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}
.sub-group {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}
.logo-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 6px;
  text-decoration: none;
  cursor: pointer;
  & > h2 {
    font-size: 1.5rem;
    color: #494949;
    &:hover {
      color: #185e98;
    }
  }
}
.logo {
  border-radius: 8px;
  height: 38px;
  margin-right: 0px;
  width: 38px;
}
.input {
  width: 32%;
  border: 1px solid #ccc;
  height: 35px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border-radius: 4px;
  color: #555;
  display: flex;
  overflow: hidden;
  & > span {
    height: 100%;
    min-width: 80px;
    text-align: center;
    line-height: 32px;
    background-color: #eee;
    font-size: 1.3rem;
    color: var(--text-color);
    border-right: 1px solid #ccc;
  }
  & > input {
    padding: 0 10px;
    border: 0px;
    height: 100%;
    color: var(--text-color);
    flex: 1;
    font-size: 1.3rem;
  }
  &:focus-within {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
    border-color: #66afe9;
    outline: 0;
  }
}
.bt-cal {
  width: 90%;
  display: flex;
  justify-content: center;
  margin: 30px 0;
}
.title-result {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  & > span {
    display: block;
    width: 22%;
  }
}
//ad
.container-advertisement {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px 20px;
  & > h5 {
    font-size: 1.6rem;
    font-weight: 300;
    text-align: center;
    margin: 0 auto;
    opacity: 0.8;
    margin-bottom: 10px;
  }
}
.image {
  object-fit: contain;
  width: 100%;
  border-radius: 16px;
}
.detail-advertisement {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 30px 10px 75px;

  & > div {
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    align-items: center;
    & > span {
      color: #494949;
      font-size: 1.4rem;
      line-height: 1.6;
    }
  }
}
.score-standard {
  color: var(--primary-color);
  cursor: pointer;
  text-decoration: none;
}
.icon {
  font-size: 1.4rem;
  color: var(--primary-color);
}
//responsive

@media (max-width: 1024px) {
  .detail-advertisement {
    padding: 10px 20px 10px 20px;
  }
  .title-result {
    & > span {
      display: block;
      width: 45%;
    }
  }
  .wrapper {
    overflow: hidden;
    padding: 0 0px 74px;
  }
  .member {
    & > div {
      flex-wrap: wrap;
      & > div {
        width: 50%;
      }
    }
  }
  .member-detail {
    width: 50%;
  }
  .container-advertisement {
    padding: 10px;
  }
}
@media (max-width: 768px) {
  .detail-advertisement {
    padding: 10px 00px 10px 00px;
  }
  .container {
    display: flex;
    flex-direction: column;
  }
  .container-image {
    width: 100%;
    display: flex;
    justify-content: center;

    & > h5 {
      margin: 0;
      margin-top: -7px;
      margin-bottom: 2px;
      color: var(--primary-color);
    }
  }
  .image {
    object-fit: contain;
    margin: 2px auto;
    width: 40%;
    border-radius: 16px;
  }

  .advertisement-desktop {
    display: none;
  }
  .advertisement-mobile {
    display: block;
  }
  .container-advertisement {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 10px 10px;
  }
  .container-image {
    width: 50%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
  }
  .image {
    object-fit: contain;
    margin: 2px auto;
    width: 70%;
    border-radius: 16px;
  }
  .input_mobile {
    width: 50%;
  }
}
@media (max-width: 576px) {
  .benefits {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    flex: 1;
    gap: 8px;
  }
  .benefits-list {
    gap: 8px;
  }
  .Group {
    flex-direction: column;
    justify-items: flex-start;
    align-items: flex-start;
    gap: 10px;
  }
  .image {
    width: 100%;
    border-radius: 8px;
    margin-left: -5px;
    & ~ div {
      margin-left: -5px;
    }
  }
  .detail-advertisement {
    margin-top: 10px;
    gap: 0px;
  }
  .program {
    flex-direction: column;
    align-items: flex-start;
  }
  .document {
    margin-top: 20px;
  }
  .document {
    & > div {
      & > h2 {
        margin: 15px 0 4px;
      }
    }
  }
  .basic-course {
    padding: 10px 12px;
    min-height: 65px;
  }
  .input_mobile {
    width: 70%;
  }
  .input {
    height: 32px;
    & > input {
      padding: 0 5px;
    }
  }
  .program {
    margin: 10px 0 10px 0;
  }
  .bt-cal {
    width: 90%;
    display: flex;
    justify-content: center;
    margin: 30px 0 0 20px;
  }
}
